@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

.page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5% 0 10%;
    /*margin-top: -10vmax;*/

    color: #433012;
    background-image: url("sand.png");
    background-size: cover;
    z-index: 0;
}

.services_header {
    font-family: "Crimson Text", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 2.5vmax
}

.services_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
}

.services_ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.services_li {
    text-align: center;
    flex: 0 0 33.33%;
    padding: 10px;
    box-sizing: border-box;
    /*font-size: 1.5rem;*/
    font-family: "Gothic A1", sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 2;

    font-size: 1.1rem;
}


@media (max-width: 950px) {
    .page {
        margin: 0;
    }

    .services_li {
        flex: 100%;
        font-size: 1.15rem;
        text-align: right;
    }

    .services_header {
        font-size: 5vmax;
    }
}

