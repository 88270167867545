@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');


.splash_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;

    background: url("top.png");
    background-size: cover;
    background-position: 25% 70%;
}

.splash_left {
    flex: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.splash_left img {
    z-index: 10;
    width: 60%;
}
.splash_left h1 {
    font-family: "Crimson Text", serif;
    font-weight: 800;
    font-style: normal;
    color: white;
    text-shadow: black 1px 1px 1px;
    text-align: center;
    font-size: 5rem;

    z-index: 1;
}

.splash_left h2 {
    font-family: "Gothic A1", sans-serif;
    font-weight: 600;
    font-style: normal;
    /*color: #CAAE7C;*/
    color: #f2f2f2;
    font-size: 1.7vmax;
    text-align: center;

    z-index: 1;
}

.splash_right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    position: relative;
    flex: 30%;

    border-left: #CAAE7C 25px solid;
    text-align: end;

    background: url("sand.png");

    z-index: 1;
}


.splash_right h2 {
    font-family: "Crimson Text", serif;
    font-weight: 600;
    font-style: normal;
    font-size:3.30vmax;
    text-align: end;

    /*width: auto;*/
}

.splash_right p {

    /*border: red 1px solid;*/
    border-bottom: #3D2B0E 2px solid;
    padding-bottom: 10px;

    font-family: "Gothic A1", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 1.5vmax;
}

/*.splash_right_p1 {*/
/*    width: auto;*/
/*}*/

.splash_right_p2 {
    width: 75%;
}
.splash_right_p3 {
    width: 85%;
}
.splash_right_p4 {
    width: 95%;
}


.splash_right_text_container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    padding-bottom: 5vmin;
    width: 90%;

    text-align: end;

    /*border: red 1px solid;*/
}

.filter {
    position: absolute;
    width: 150vw;
    height: 100vh;
    background: #000;
    opacity: .5;
    z-index: 0;
}

@media (min-width: 951px){

    .splash_container{
        overflow: hidden;
    }
    .splash_container {

    }
    .filter {
        width: 150vw;
    }
    .skew{
        position: relative;
        height: 100vh;
        width: 50vw;
        right: -40%;
        transform: skewX(-30deg);
        border-left: #CAAE7C 25px solid;

        background: url("sand.png");
        z-index: 0;
    }

    .splash_right{
        flex: 30%;
        all: unset;
        position: relative;
    }


    .right_content{
        display: flex;
        flex-direction: column;

        justify-content: end;
        align-items: end;
        position: absolute;
        width: 80%;
        right: 5%;
        bottom: 0;

        z-index: 1;
    }
    .right_content_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
    }
}

@media (max-width: 950px) {
    .filter {
        width: 100vw;
        height: 110vh;

    }
    .splash_container {
        flex-direction: column;
        height: 160vh;
        overflow-x: hidden;
        /*height: auto;*/

        /*background-position-x: 80%;*/
        background-position-y: 0%;
        background-size: 100vh;
        /*background-repeat: repeat;*/
    }
    .splash_right_text_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        text-align: start;
        padding-left: 5vmin;
        width: 90%;
    }

    .splash_right p {
        font-size: 1.8vmax;
        border-bottom: #3D2B0E 2px solid;

    }
    .splash_right h2 {
        font-size: 5vmax;
    }

    .splash_right{
        border-left: none;
        border-top: #CAAE7C 25px solid;

    }
    .right_content {
        display: flex;
        flex-direction: column;
        /*border: 1px red solid;*/
        width: 100%;
        padding-bottom: 5%;
    }
    .right_content_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .splash_left{
        justify-content: center;
        overflow-x: hidden;

    }
    .splash_left h1 {
        font-size: 2rem;
    }
}


