@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');

.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    font-family: "Gothic A1", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #FFF4DE;
    background-image: url("bgcon.png");
}


.footer_text {
    flex: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    textwrap: wrap;
    text-align: left;
}

.footer_icons {
    flex: 50%;
    margin: 20px 0 20px 0;

    text-align: right;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.footer_icons p {
    margin-right: 10px;
}

.footer_icons a img, .footer_social a img {
    width: 38px;
    margin: 5px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.footer_social {
    display: flex;
    flex-direction: row;
}

.footer_icons a img:hover, .footer_social a img:hover  {
    filter: brightness(75%);
}

@media (max-width: 950px) {
    .footer {
        flex-direction: column-reverse !important;
    }

    .footer_text {
        padding-bottom: 5%;
        text-align: center;
    }
    .footer_icons a img, .footer_social a img {
        margin-top: 2%;
    }
}