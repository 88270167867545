@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');


nav {
    color: white;
    z-index: 2;
}

.nav_bar {
    display: flex;
    justify-content: center;

    position: absolute;
    width: 100vw;

    font-size: 1vmax;
}

.nav_bar a {
    color: white;
    text-decoration: none;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.nav_bar a:hover {
    color: lightgray;
}



.ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end;

    list-style-type: none;
    width: 45%;
    position: relative;
}

.li {
    font-family: "Gothic A1", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.logo {
    width: 4%;
    transform: translateY(20%);
}

@media (max-width: 950px) {
    .ul {
        width: 90%;
        justify-content: space-between;
        padding: 0;
    }
    .logo {
        transform: translate(50%, 5%);
    }

    .nav_bar {
        font-size: 2vmax;
        margin-top: 20px;
    }

}