@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
.contact_us_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 10vmin 0;

    background-color: #fdf3de;
}

.contact_details_container {
    position: relative;
    /*width: 100%;*/
    width: fit-content;
    font-size: 1vmax;
    margin-top: 2%;
    padding: 10% 0 0 10%;

    color: #433012;
}

.contact_details_container div{
    position: relative;
    margin: 0 0 0 20%;
}

.contact_details_container img{
    width: 30px;
    margin-right: 5%;
}


.contact_detail_line {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;

    font-size: 1.5rem;
    color: #3D2B0E;
    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    text-decoration: none;
}

.contact_detail_line:hover {
    transform: scale(1.05);
}

.contact_header {
    font-family: "Crimson Text", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 2.5vmax
}

.contact_left {
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex: 50%;
    margin-top: -24.5vmin;
}

.contact_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 50%;
}


.contact_pic_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    position: relative;

    transform: translatey(10%);

    /*border: 5px solid white;*/
}
.contact_pic {
    width: 40vmax;
}

.contact_pic_border {
    width: 20px;
    height: 90%;
    position: absolute;
    top: -25px;
    right: -10px;

    background-color: #CAAE7C;
}

@media (max-width: 950px) {
    .contact_us_container {
        flex-direction: column;
    }

    .contact_left {
        align-items: center;
        margin: 0;
    }
    .contact_right {
        width: 90vmin;
    }

    .contact_details_container {
        margin-top: 5%;
        font-size: 1.8vmax;
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        align-items: start;
        width: auto;
        padding: 5% 0 0 5%;


    }

    .contact_details_container div{
        position: relative;
        margin: 0%;
    }

    .contact_header {
        font-size: 5vmax;
    }

    .contact_pic{
        width: 75vw;
    }

}
