button {
    width: 150px;
    border: none;
    padding: .5vmax;
    position: relative;
    margin: 30px 0 0 auto;

    color: #433012;
    font-family: "Gothic A1", sans-serif;
    font-weight: bold;
    background-color: #CAAE7C;

    cursor: pointer;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

button:hover {
    background-color: #8f7b56;
}

.contact_container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    max-width: 800px;

    background-image: url("bgcon.png");
}

.contact_border {
    width: 20px;
    height: 90%;
    position: absolute;
    left: -10px;
    top: -30px;
    z-index: 1;

    background-color: #CAAE7C;
}

.contact_form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    padding: 30px;
}

.contact_label {
    color: #FFF4DE;
}

input {
    height: 30px;
}

textarea {
    height: 150px;
}

input, textarea {
    border: none;
    background: #FFF4DE;
}