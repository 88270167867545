@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');

.who_we_are_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5vmax 0;

    background-color: #fdf3de;
    color: #433012
}

.text_header {
    font-family: "Crimson Text", serif;
    font-weight: 800;
    font-style: normal;
    font-size: 2.5vmax
}

.text_para {
    width: 75%;
    padding: 0 0 10% 0;

    font-family: "Gothic A1", sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 2;

    font-size: 1.1rem;
}

.text_container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    flex: 50%;
    padding: 0 0 5% 10%;
    /*font-size: 1vmax;*/
    /*border: blue 1px solid;*/
}

.pic_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    position: relative;
    flex: 50%;
    /*padding: 5% 5% 5% 5%;*/
    /*padding: 0% 0 0 0;*/

    /*border: 1px red solid;*/
    /*transform: translatex(10%);*/

    z-index: 1;
}

.bordered_pic1 img, .bordered_pic2 img {
    max-width: 100%;
}



.bordered_pic1 {
    position: relative;
    height: fit-content;

}

.bordered_pic2 {
    position: relative;
    height: fit-content;
    transform: translate(-50%, 30%);
    z-index: 2;
}

.pic_border {
    position: absolute;
    top: -20px;
    left: -10px;
    width: 20px;
    height: 80%;

    background-color: #CAAE7C;
}


@media (max-width: 950px) {
    .who_we_are_container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 10%;

        text-align: left;
        font-size: 1.25rem;
    }
    .text_container p {
        font-size: 1rem;
    }

    .text_header {
        font-size: 5vmax;
    }
    .pic_container {
        /*transform: translatex(0);*/
    }

    .bordered_pic1 img {
        width: 50vw;
        /*object-fit: cover;*/
    }

    .bordered_pic2 {
        display: none;
    }

}

@media (max-width: 550px) {
    .bordered_pic1 img {
        width: 75vw;
        object-fit: cover;
    }
}








