*{
    scroll-behavior: smooth;
}

/**Scrollbar**/
::-webkit-scrollbar {
    max-width: 10px;
    width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #3d2b11;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8f7b56;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6b5b40;
}

::-webkit-scrollbar-thumb:active {
    background: #493e2c;
}

body{
    width: 100vw;
    overflow-x: hidden;
    /*overflow: hidden;*/
    position: relative;

}

.contact_container{
    background: url("images/bgcon.png");
}

